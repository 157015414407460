'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import HeaderImage from '../../../public/assets/images/home_header.png';
import Logo from '../../../public/assets/images/jobreel_logo.svg';
import { useProfile } from '@/modules/profile/profile.queries';
import { useListBusinessesForUser } from '@/modules/business/business.queries';
import { useUser } from '@/modules/user/user.queries';

export default function Home() {
  const router = useRouter();
  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: profile, isLoading: isProfileLoading } = useProfile();
  const { data: businesses, isLoading: isBusinessesLoading } =
    useListBusinessesForUser();

  useEffect(() => {
    if (isUserLoading || isProfileLoading || isBusinessesLoading) {
      return;
    }

    if (!user) {
      return;
    }

    if (!profile && !businesses?.at(0)) {
      return router.push(`/onboarding/mode`);
    }
    if (!!businesses?.at(0)) {
      return router.push(`/app/my-jobs`);
    }
    if (!!profile) {
      return router.push(`/app/inbox`);
    }
  }, [
    isProfileLoading,
    isBusinessesLoading,
    isUserLoading,
    profile,
    businesses,
    user,
    router,
  ]);

  const onClick = useCallback(() => {
    if (!user) {
      router.push(`/auth?from=signin`);
    } else if (!!businesses?.length) {
      router.push(`/app/my-jobs`);
    } else if (!!profile) {
      router.push(`/app/inbox`);
    }
  }, [user, businesses, profile, router]);

  return (
    <>
      <main className='flex h-[calc(100svh-80px)] flex-1 flex-col items-center justify-between bg-royal-blue'>
        <div className={'ld:w-1/4 flex flex-1 flex-col p-4'}>
          <div
            className={
              'm-auto flex w-3/4 flex-1 flex-col items-center justify-center md:w-4/12'
            }
          >
            <Image
              src={HeaderImage}
              alt={'jobreel header image'}
              aria-label={'Group of person in bubbles'}
              className={'h-auto w-full'}
            />
            <Logo className={'my-8 h-auto w-full fill-current text-white'} />
          </div>
          <div className={'flex flex-col'}>
            <button
              onClick={onClick}
              className={
                'my-6 flex w-full items-center justify-center rounded-full bg-ruby py-2 text-center font-moderat text-jb-base font-bold text-white'
              }
            >
              Continue with Phone Number
            </button>
            <p
              className={'font-regular mt-4 text-center text-jb-xs text-white'}
            >
              By tapping {'"Continue"'}, you agree to our{' '}
              <a href={'https://jobreel.io/tos'} target={'_blank'}>
                Terms of service
              </a>{' '}
              and agree you are at least 18 years of age. Learn how we process
              your data in our{' '}
              <a href={'https://jobreel.io/privacy'} target={'_blank'}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href={''} target={'_blank'}>
                Cookies Policy
              </a>
              .
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
