import { useQuery } from '@tanstack/react-query';
import {
  Business_Current,
  Business_FindBySlug,
  Business_GetCategories,
  Business_ListForUser,
  Business_Retrieve,
} from '@/chore/services/Business.service';
import {
  getCurrentBusiness,
  storeCurrentBusiness,
} from '@/lib/utils/functions/authentication';

export function useBusinessByHash(businessHash: string) {
  const query = useQuery({
    queryKey: [`business-${businessHash}`],
    queryFn: async () => await Business_Retrieve(businessHash ?? ''),
    enabled: !!businessHash.length,
    refetchInterval: 5 * 60 * 1000,
  });

  return query;
}

export function useBusinessBySlug(businessSlug: string) {
  const query = useQuery({
    queryKey: [`business-${businessSlug}`],
    queryFn: async () => await Business_FindBySlug(businessSlug),
    enabled: !!businessSlug.length,
    refetchInterval: 5 * 60 * 1000,
  });

  return query;
}

export function useListBusinessesForUser() {
  const query = useQuery({
    queryKey: ['list-businesses-for-user'],
    queryFn: async () => {
      const businesses = await Business_ListForUser();
      if (!getCurrentBusiness() && !!businesses.length) {
        storeCurrentBusiness(businesses[0].hash);
      }
      return businesses;
    },
    refetchInterval: 1 * 60 * 5000,
  });
  return query;
}

export function useBusinessCategories() {
  const query = useQuery({
    queryKey: ['business-categories'],
    queryFn: Business_GetCategories,
    refetchInterval: 1 * 60 * 60 * 1000,
  });
  return query;
}

export function useCurrentBusiness() {
  const query = useQuery({
    queryKey: ['business-current'],
    queryFn: Business_Current,
    enabled: !!getCurrentBusiness(),
    refetchInterval: 5 * 60 * 1000,
    retry: 1,
  });
  return query;
}
